import React from "react";
import storeImage from "../img/store-image.png";
import storeImageMobile from "../img/store-image-mobile.png";
import assetImage from "../img/AssetMynd.png";
import BackIcon from "../img/icons/Arrow-back.svg";
import ToolsIcon from "../img/icons/tools-icon.svg";
import NextIcon from "../img/icons/next-arrow.svg";
import SendIcon from "../img/icons/send-icon.svg";
import Button from "@material-ui/core/Button";
import { Link } from "gatsby";
import Grid from "@material-ui/core/Grid";
import withWidth from "@material-ui/core/withWidth";
import Layout from "../components/Layout";

export const StorePageTemplate = (props) => {
  const { width } = props;
  return (
    <Layout hideMenu hideFooter>
      <div className="store-page">
        <div
          className="background-image"
          style={{
            backgroundImage:
              width == "xs" ? `url(${storeImageMobile})` : `url(${storeImage})`,
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="background-blur">
            <div
              className="overlay-image"
              style={{
                backgroundImage: `url(${assetImage})`,
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="container">
                <div className="redirect-container">
                  <div className="redirect">
                    <h2>
                      <ToolsIcon /> Þessi Síða er vinnslu
                    </h2>
                    <p className="main-text">
                      Samfélagsgróðurhús er á fullu að vinna við að smíða
                      notendavæna og hnitmiðaða heildarlausn fyrir kaup og
                      viðhald á gróðurhúsunum. <br />
                      <b>Ef að áhugi liggur fyrir endilega hafðu samband!</b>
                    </p>
                    <Grid
                      container
                      justify="space-between"
                      alignItems="center"
                      className="btn-container"
                    >
                      <Grid item>
                        <Link to="/">
                          <p>
                            <BackIcon /> Til baka á forsíðu
                          </p>
                        </Link>
                      </Grid>
                      <Grid item>
                        <a href="mailto:info@samfelagsgrodurhus.com">
                          <Button
                            variant="contained"
                            color="primary"
                            className="send-btn"
                            endIcon={<SendIcon />}
                          >
                            Hafa samband
                          </Button>
                        </a>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-nav">
            <Grid container justify="space-between">
              <Grid item className="price-container">
                <div className="total-caption">Samtals:</div>
                <div>
                  <p className="price">123.456 kr.</p>
                  <p className="price-sub">með sendingu og uppsetningu</p>
                </div>
              </Grid>
              <Grid item className="next-container">
                <div>Áætluð afhending 1-2 vikur</div>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled
                    className="send-btn"
                    endIcon={<NextIcon />}
                  >
                    Áfram
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </Layout>
  );
};

StorePageTemplate.propTypes = {};

const StorePage = (props) => {
  return <StorePageTemplate {...props} />;
};

export default withWidth()(StorePage);
